<template>
  <div>
    <div class="propety">
      <div style="min-height: 600px">
        <el-form :model="data" ref="ListingForm" :rules="rules">
          <div class="pages">
            <div class="form_in">

              <!-- Descriptions -->
              <div>
                <div class="top-prodect" >
                  <span class="title-text">Descriptions</span>
                </div>
                <div >
                  <div class="set_width">
                    <el-form-item prop="headline" :rules="rules.required">
                      <p class="propety-title">
                        Headline (required for some websites)
                      </p>
                      <el-input v-model="data.headline" placeholder></el-input>
                    </el-form-item>
                    <el-form-item prop="description" :rules="rules.required">
                      <p class="propety-title">Main Description</p>
                      <el-input v-model="data.description" type="textarea" resize="none" :autosize="{ minRows: 1 }">
                      </el-input>
                    </el-form-item>
                    <el-form-item>
                      <p class="propety-title">First Floor</p>
                      <el-input v-model="data.first_floor" type="textarea" resize="none" :autosize="{ minRows: 1 }">
                      </el-input>
                    </el-form-item>
                    <el-form-item>
                      <p class="propety-title">Second Floor</p>
                      <el-input v-model="data.second_floor" type="textarea" resize="none" :autosize="{ minRows: 1 }">
                      </el-input>
                    </el-form-item>
                    <el-form-item v-if="data.lower_level">
                      <p class="propety-title">Lower Level</p>
                      <el-input v-model="data.lower_level" type="textarea" resize="none" :autosize="{ minRows: 1 }">
                      </el-input>
                    </el-form-item>
                    <el-form-item v-if="data.other_structure">
                      <p class="propety-title">Cottage / Other Structure</p>
                      <el-input v-model="data.other_structure" type="textarea" resize="none" :autosize="{ minRows: 1 }">
                      </el-input>
                    </el-form-item>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </el-form>
      </div>
      <div class="btn-save">
        <el-button class="primary-button" @click="save" v-loading.fullscreen.lock="fullscreenLoading">
          <p class="row btn-row">
            <span>Save/Update</span><i class="el-icon-right"></i>
          </p>
        </el-button>
      </div>
    </div>
    <!-- 保存的按钮 -->
    <div class="propety_save" @click="save" v-if="btnFlag">
      <el-tooltip class="item" effect="dark" content="Save" placement="bottom">
        <img src="../../assets/img/save.png" alt />
      </el-tooltip>
    </div>
  </div>
</template>
<script>
import PropertyWeek from "./Property/PropertyWeek"
import { apiGetListing, apiUpdateListing, apiPublishListing, apiHideListing, apiDeactivateListing } from "../../API/api";
import { Minixs } from "../../js/mixins";
export default {
  components: { PropertyWeek },
  mixins: [Minixs],
  data() {
    return {
      loadingFile: false,
      fan_number: "Yes",
      deactivated_reason: "",
      fullscreenLoading: false,
      btnFlag: true,
      fileName: "Attached Files", // 文件名
      fileUrl: "", // 文件访问地址
      action: "hide",
      map: {}, // 用来存放房间和浴室的对应关系
      data: {
       
      },
      rules: {
        required: [
          {
            required: true,
            message: "this information is required",
            trigger: ["blur", "change"],
          },
        ],
      },
      availabilities: [],
    };
  },
  methods: {

    selectAction(val) {
      if (val === "deactivate") {
        this.showReason = true;
      } else {
        this.showReason = false;
      }
    },
    // 修改Action动作
    changeAction(res) {
      if (res.deleted_at) {
        this.action = "deactivate";
        this.deactivated_reason = res.deactivated_reason;
        this.showReason = true;
      } else if (res.publish) {
        this.action = "publish";
        this.showReason = false;
      } else {
        this.action = "hide";
        this.showReason = false;
      }
    },
    // 数据格式转换
    changeDate(val) {
      let res = Object.assign({}, val)
      // 转换枚举类型
      if (res.occupancy_tax_file) {
        this.fileName = res.occupancy_tax_file.file_name;
        this.fileUrl = res.occupancy_tax_file.url;
        res.occupancy_tax_file = res.occupancy_tax_file.occupancy_tax_file_uuid;
      }
      if (res.priority) res.priority = res.priority.id;
      if (res.area) res.area = res.area.id;
      if (res.ac_types) {
        let arr = []
        res.ac_types.forEach(item => {
          arr.push(item.id)
        });
        res.ac_types = arr
      };
      if (res.heating_type) res.heating_type = res.heating_type.id;
      if (res.tv_service) res.tv_service = res.tv_service.id;
      if (res.coffee_maker_type)
        res.coffee_maker_type = res.coffee_maker_type.id;
      if (res.stove_type) res.stove_type = res.stove_type.id;
      if (res.toaster_type) res.toaster_type = res.toaster_type.id;
      if (res.grill_type) res.grill_type = res.grill_type.id;
      if (res.pool_type) res.pool_type = res.pool_type.id;
      if (res.bedrooms.length === 0) {
        for (let i = 0; i < res.bedroom_number; i++) {
          res.bedrooms.push({
            floor_level: "",
            en_suite: false,
            beds: [{ type: "", number: 0 }],
          });
        }
      } else {
        for (let i = 0; i < res.bedrooms.length; i++) {
          res.bedrooms[i].floor_level = res.bedrooms[i].floor_level.id;
          let beds = res.bedrooms[i].beds;
          for (let j = 0; j < beds.length; j++) {
            res.bedrooms[i].beds[j].type = beds[j].type.id;
          }
        }
      }

      for (let i = 0; i < res.bathrooms.length; i++) {
        res.bathrooms[i].type = res.bathrooms[i].type.id;
      }
      // 关联浴室和房间
      if (res.bathrooms.length === 0) {
        for (let i = 0; i < res.bathroom_number; i++) {
          res.bathrooms.push({
            index: "", // 关联的房间
            checked: false, // 是否是房间的私有浴室
            bidet: false,
            combination_tub: false,
            toilet: false,
            tub: false,
            shower: false,
            type: "",
          });
        }
      } else {
        for (let i = 0; i < res.bathrooms.length; i++) {
          if (res.bathrooms[i].bedroom) {
            res.bathrooms[i]["checked"] = true;
            res.bedrooms.forEach((bedroom, index) => {
              if (res.bathrooms[i].bedroom === bedroom.bedroom_uuid) {
                res.bathrooms[i]["index"] = index;
                this.map[i] = index;
              }
            });
          } else {
            res.bathrooms[i]["checked"] = false;
            res.bathrooms[i]["index"] = "";
          }
        }
      }

      // 删除不能传空值的key
      delete res.homeowner;
      // 删除该页面不会用到的key
      delete res.images;
      delete res.availabilities;
      delete res.rates;
      delete res.requirement;
      return res;
    },
    getListing() {
      apiGetListing(this.$route.params.id).then((res) => {
        this.availabilities = res;
        setTimeout(() => {
          if (this.$refs.retaWeek) this.$refs.retaWeek.setRatesList()
        }, 3000);
        this.$store.commit("setListing", JSON.parse(JSON.stringify(res)));
        this.$store.commit("setPropertyInfor", true);
        this.$store.commit("setPropertyReuirement", true);
        if (res.availabilities.length != 0 || res.rates.length != 0) {
          this.$store.commit("setPropertyCalendar", true);
        }
        if (res.images.length != 0) {
          this.$store.commit("setPropertyPhoto", true);
        }
        this.data = this.changeDate(res);
        this.changeAction(res);
      });
    },
    
    save() {
      this.$refs.ListingForm.validate((valid) => {
        if (valid) {
          let listing = JSON.parse(JSON.stringify(this.data));
          
          this.fullscreenLoading = true;
          if (this.$route.params.id) {
            // 修改
            apiUpdateListing(this.$route.params.id, listing)
              .then(
                (res) => {
                  this.$store.commit(
                    "setListing",
                    JSON.parse(JSON.stringify(res))
                  );

                  this.$store.commit("setPropertyInfor", true);
                  if (res.availabilities.length != 0 || res.rates.length != 0) {
                    this.$store.commit("setPropertyCalendar", true);
                  }
                  if (res.images.length != 0) {
                    this.$store.commit("setPropertyPhoto", true);
                  }
                  this.data = this.changeDate(res);
                  if (this.action === "publish" && !res.publish) {
                    // 未发布才发布
                    return apiPublishListing(this.$route.params.id).then(
                      (res) => {
                        this.$router.push({
                          name: "property-descriptions",
                          params: { id: res.listing_id },
                        });
                        this.$message.success("Listing publish successfully");
                      }
                    );
                  } else if (
                    this.action === "deactivate" &&
                    (!res.deleted_at ||
                      res.deactivated_reason != this.deactivated_reason)
                  ) {
                    // 未禁用才禁用
                    return apiDeactivateListing(res.listing_id, {
                      deactivated_reason: this.deactivated_reason,
                    }).then((res) => {
                      this.$router.push({
                        name: "property-descriptions",
                        params: { id: res.listing_id },
                      });
                      this.$message.success("Listing deactivate successfully");
                    });
                  } else if (
                    this.action === "hide" &&
                    (res.publish || res.deleted_at)
                  ) {
                    // 发布或禁用才变为隐藏
                    return apiHideListing(res.listing_id).then((res) => {
                      this.$message.success("Listing hide successfully");
                      this.$router.push({
                        name: "property-descriptions",
                        params: { id: res.listing_id },
                      });
                    });
                  } else {
                    this.$router.push({
                      name: "property-descriptions",
                      params: { id: res.listing_id },
                    });
                    this.$message.success(
                      "Listing information successfully modified"
                    );
                  }
                },
                (err) => {
                  this.$message.error("Listing information modify fail");
                }
              )
              .catch((err) => {
                this.$message.error(err);
              }).finally(() => {
                this.fullscreenLoading = false;
              });
          } 
        } else {
          setTimeout(() => {
            let isError = document.getElementsByClassName("is-error");
            let input = isError[0].querySelector("input");
            if (input) {
              input.focus();
            } else {
              isError[0].querySelector("textarea").focus();
            }
          }, 100);
        }
      });
    },
    changeStatus(selector) {
      var anchor = this.$el.querySelector(selector); // 参数为要跳转到的元素id
      document.body.scrollTop = anchor.offsetTop; // chrome
      document.documentElement.scrollTop = anchor.offsetTop; // firefox
    },
    // 为了计算距离顶部的高度，当高度大于60显示回顶部图标，小于60则隐藏
    scrollToTop() {
      let scrollHeight = window.pageYOffset || document.body.scrollHeight;
      let height = document.documentElement.scrollHeight;
      if (scrollHeight > height - 1000) {
        this.btnFlag = false;
      } else {
        if (this.$route.params.id) {
          this.btnFlag = true;
        } else {
          this.btnFlag = false;
        }
      }
    },
  },
  destroyed() {
    window.removeEventListener("scroll", this.scrollToTop);
  },

  created() {

    if (this.$route.params.id) {
      // if (this.$store.state.listing.listing_id === this.$route.params.id - 0) {
      //   this.data = this.changeDate(
      //     JSON.parse(JSON.stringify(this.$store.state.listing))
      //   );
      //   this.changeAction(this.data);
      // } else {
      this.getListing();
      // }
    }
  },
};
</script>
<style lang="scss" scoped>
@import "../../css/button.scss";
@import "./Property.scss";
</style>
